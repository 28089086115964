import { useEffect, useState } from "react"
import { Link, NavLink, useNavigate } from "react-router-dom"
import { userValidate } from "../../../api/login/Login"
import { MdOutlineNotificationsActive } from "react-icons/md";
import paymentIcon from "../../../asesets/adminImage/hand-money-rupee-coin-icon.svg";
import { MdOutlineDisplaySettings } from "react-icons/md";
import { FiPackage } from "react-icons/fi";
import { TbCircleNumber8 } from "react-icons/tb";
import { IoCashOutline } from "react-icons/io5";
import { MdOutlineQuickreply } from "react-icons/md";
import { IoIosCash } from "react-icons/io";
import { LiaServicestack } from "react-icons/lia";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Button, useMediaQuery, useTheme } from '@mui/material';
import { Menu as MenuIcon, Notifications, AccountCircle, ExitToApp } from '@mui/icons-material'


function AsideAdmin({ isActive, overlayClick, handleMenuClick, clickoVer, tokenNoti, services, isInstantpaypresnt }) {
    console.log("isInstantpaypresnt", isInstantpaypresnt);
    const navigate = useNavigate()
    const [report, setReport] = useState(false)
    const [payment, setPayment] = useState(false)
    const [setting, setSetting] = useState(false)

    const logoutPage = () => {
        window.localStorage.setItem('login', false)
        navigate('/home')
        window.location.reload()
    }


    const logOut = () => {
        window.localStorage.removeItem('userToken')
        window.localStorage.removeItem('userIdToken')
        window.localStorage.removeItem('openMenu')

        // window.location.reload()
        navigate('/login-area')
    }
    const [retailer, setretailer] = useState(false)
    const [distributer, setDistributer] = useState(false)
    const [superdistributer, setsuperDistributer] = useState(false)
    const [zsm, setZsm] = useState(false);
    const [asm, setAsm] = useState(false);
    const [rsm, setRsm] = useState(false)

    const valdateApi = async () => {
        try {
            const res = await userValidate()
            console.log(res.data);
            if (res.data.user_type_id?._id == '65e2f15785bfd78f9866c090') {
                setretailer(true);
            } if (res.data.user_type_id?._id == '65f3fb87a6a2a92f979b47eb') {
                setsuperDistributer(true)
            }

            if (res.data.user_type_id?._id == '65e2f1a585bfd78f9866c09b') {
                setDistributer(true)
            }
            if (res.data.user_type_id?._id == '6706853731bad9259704b172') {
                setZsm(true)
            }
            if (res.data.user_type_id?._id == '6706856a31bad9259704b173') {
                setAsm(true)
            }
            if (res.data.user_type_id?._id == '6706856a31bad9259704b174') {
                setRsm(true)
            }

        } catch (error) {

        }
    }
    useEffect(() => {
        valdateApi()
    }, [])


    const [allAside, setAllAside] = useState({
        report: false,
        payment: false,
        setting: false,
        disput: false,
        disput: false,
        services: false,
        loan: false,
        network: false,
        earning: false

    });

    const changAllAside = (e) => {
        handleMenuClick(e)
        const clone = { ...allAside };
        for (const iterator in clone) {
            clone[iterator] = false;
        }
        setAllAside(clone);
    }

    const routeMap = {
        "206": "/aeps-cash-deposit",
        "19": "/ccbill_payment_report",
        "18": "/electricity-bills",
        "33": "/mobile-reacharge",
        "13": "/dth-reacharge",
        "11": "/gas-bills",
        "10": "/postpaid-recharge",
        "5": "/Boardband",
        "35": "/airtel-cms",
        "12": "/landline-bills",
        "208": "/aeps-OnBoarded",
        "207": "/aeps-OnBoarded",
        "9": "/fastag-recharge",
        "188": "/money-transfer",
        "28": "/water-bills",
        "15": "/health-insurance-bill",
        "3": "/educational-fees",
        "16": "/lpg-gasbill-payment",
        "31": "/nmrc-paymentbill",
        "36": "/quickdhan-payment",
        "8": "/insurance-payment",
        "17": "/muncipaltax-paymentbill",
        "29": "/metro-recharge",
        "2": "/loan-repayment", // Note: This is duplicate of "29", you might want to resolve this
        "22": "/life-insurance",
        "4": "/housing-society",
        "14": "/health-insurance-bill", // This seems to be duplicate of "15", you might want to resolve this
        "26": "/health-insurance",
        "30": "/clubs-associations",
        "21": "/cable-tv",
        "24": "/b2b",
        "6": "/subscription",
        "25": "/rent-bill",
        "216": "/recurringdeposite",
        "7": "/muncipaltax-paymentbill",
        "1": "/hospital-pathology",
        "27": "/donation",
    };

    const filteredServices = services && Array.isArray(services)
        ? services.filter(service =>
            routeMap[service._id] &&
            !["206", "207", "208"].includes(service._id)
        )
        : [];

    const changeOpen = (val, str) => {
        const clone = { ...allAside };
        for (const iterator in clone) {
            if (iterator == str) {
                clone[str] = val;
                window.localStorage.setItem('openMenu', str)
            } else {
                clone[iterator] = false;
            }
        }
        setAllAside(clone);
    };

    useEffect(() => {
        const obj = {
            report: false,
            payment: false,
            setting: false,
            disput: false,
            package: false,
            services: false,
            loan: false,
            network: false,
            earning: false
        }
        const target = window.localStorage.getItem('openMenu')
        if (!window.localStorage.getItem('openMenu')) {
            setAllAside({
                report: false,
                payment: false,
                setting: false,
                disput: false,
                package: false,
                services: false,
                loan: false,
                network: false,
                earning: false
            })
        } else {

            for (const iterator in obj) {
                if (iterator == target) {
                    obj[target] = true;
                } else {
                    obj[iterator] = false;
                }
            }
            setAllAside(obj);
        }
    }, [])


    const [aepsOpen, setAepsOpen] = useState(false);

    const handleaepsopen = (e) => {
        e.preventDefault();
        setAepsOpen(!aepsOpen);
    };
    const storedUserType = localStorage.getItem('userType');

    return (
        <>
            <aside className={`SidebarMain mCustomScrollbar _mCS_1 mCS-autoHide sidebar ${isActive ? 'hide-sidebar' : ''}`} id="aside" >
                {/* className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside" */}
                <div id="mCSB_1" className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside" style={{ maxHeight: 'none' }} tabIndex={0}><div id="mCSB_1_container" className="mCSB_container" style={{ position: 'relative', top: 0, left: 0 }} dir="ltr">
                    <div className="MenuItem">
                        <div className="card-header card-header-header" onClick={(e) => { changAllAside(e) }}>
                            <NavLink to="/Dashboard">
                                <i>
                                    <svg x={0} y={0} viewBox="0 0 17 17">
                                        <path d="M9.444,17.000 L9.444,7.555 L17.000,7.555 L17.000,17.000 L9.444,17.000 ZM9.444,-0.000 L17.000,-0.000 L17.000,5.667 L9.444,5.667 L9.444,-0.000 ZM-0.000,11.333 L7.555,11.333 L7.555,17.000 L-0.000,17.000 L-0.000,11.333 ZM-0.000,-0.000 L7.555,-0.000 L7.555,9.444 L-0.000,9.444 L-0.000,-0.000 Z" />
                                    </svg>
                                </i>
                                <span>Dashboard</span>
                            </NavLink>
                        </div>

                    </div>


                    {storedUserType === 'Super Distributor' || storedUserType === 'Distributor' || storedUserType === "asm" || storedUserType === "zsm" || storedUserType === "rsm" ? (
                        <></>
                    ) : (
                        <>
                            <div className="MenuItem">
                                <div className="card-header collapsed" id="headingSettings" data-toggle="collapse" data-target="#collapseSettings" aria-expanded="false" aria-controls="collapseSettings">
                                    <Link href="#" onClick={() => { changeOpen(!allAside.services, "services"); }}>
                                        <LiaServicestack style={{ width: '16px', height: '22px', marginRight: '10px' }} />
                                        <span>Services</span>
                                        <i className="ic">
                                            <svg x={0} y={0} viewBox="0 0 7 11">
                                                <path d="M6.783,6.060 L2.231,10.803 C1.941,11.104 1.472,11.104 1.182,10.803 C0.893,10.501 0.893,10.012 1.182,9.710 L5.210,5.514 L1.182,1.317 C0.893,1.015 0.893,0.526 1.182,0.224 C1.472,-0.077 1.941,-0.077 2.231,0.224 L6.783,4.967 C6.928,5.118 7.000,5.316 7.000,5.514 C7.000,5.711 6.927,5.909 6.783,6.060 Z" />
                                            </svg>
                                        </i>
                                    </Link>
                                </div>
                                {/* <div id="collapseSettings" className={`SubmenuItems collapse ${allAside.services ? "mm-show" : "extra"}`} aria-labelledby="headingSettings" data-parent="#accordion">
                                    <ul>
                                        {filteredServices.map((service) => (
                                            <li key={service._id} onClick={handleMenuClick}>
                                                <NavLink to={routeMap[service._id] || "/Dashboard"} >{service.service_name}</NavLink>
                                            </li>
                                        ))}
                                        <li>
                                            <a href="#" onClick={handleaepsopen}>AEPS <i className="fas fa-chevron-right ml-2"></i> </a>
                                            <ul className={aepsOpen ? "" : "d-none"}>
                                                <li onClick={handleMenuClick}>
                                                    <NavLink to="aeps-OnBoarded">AEPS Cash Withdrawal</NavLink>
                                                </li>
                                            
                                                    <li onClick={handleMenuClick}>
                                                        <NavLink to="aeps-cash-deposit">AEPS Cash Deposit</NavLink>
                                                    </li>
                                                

                                                <li onClick={handleMenuClick}>
                                                    <NavLink to="aeps-payout-details">Aeps Payout Details</NavLink>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div> */}
                                <div id="collapseSettings" className={`SubmenuItems collapse ${allAside.services ? "mm-show" : "extra"}`} aria-labelledby="headingSettings" data-parent="#accordion">
                                    <ul>
                                        {services && Array.isArray(services) && services.map((service) => {
                                            if (routeMap[service._id]) {
                                                return (
                                                    <li key={service._id} onClick={handleMenuClick}>
                                                        <NavLink to={routeMap[service._id] || "/Dashboard"}>
                                                            {service.service_name}
                                                        </NavLink>
                                                    </li>
                                                );
                                            }
                                            return null;
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}

                    {storedUserType === 'Super Distributor' || storedUserType === 'Distributor' || storedUserType === "asm" || storedUserType === "zsm" || storedUserType === "rsm" ? (

                        <div className="MenuItem ">
                            <div className="card-header collapsed" id="headingSettings" data-toggle="collapse" data-target="#collapseSettings" aria-expanded="false" aria-controls="collapseSettings">
                                <Link href="#" onClick={() => { changeOpen(!allAside.network, "network"); }}>
                                    <MdOutlineDisplaySettings style={{ width: '16px', height: '22px', marginRight: '10px' }} />
                                    <span>Network</span>
                                    <i className="ic">
                                        <svg x={0} y={0} viewBox="0 0 7 11">
                                            <path d="M6.783,6.060 L2.231,10.803 C1.941,11.104 1.472,11.104 1.182,10.803 C0.893,10.501 0.893,10.012 1.182,9.710 L5.210,5.514 L1.182,1.317 C0.893,1.015 0.893,0.526 1.182,0.224 C1.472,-0.077 1.941,-0.077 2.231,0.224 L6.783,4.967 C6.928,5.118 7.000,5.316 7.000,5.514 C7.000,5.711 6.927,5.909 6.783,6.060 Z" />
                                        </svg>
                                    </i>
                                </Link>
                            </div>
                            <div id="collapseSettings" className={`SubmenuItems collapse ${allAside.network ? "mm-show" : "extra"}`} aria-labelledby="headingSettings" data-parent="#accordion">
                                <ul>
                                    {(storedUserType === 'Super Distributor' || storedUserType === 'Distributor') && (
                                        <>
                                            <li onClick={handleMenuClick}>
                                                <NavLink to="network">Downstream</NavLink>
                                            </li>

                                            <li onClick={handleMenuClick}>
                                                <NavLink to="earning-report">Earning Report</NavLink>
                                            </li>
                                        </>
                                    )}
                                    {(storedUserType === "asm" || storedUserType === "zsm" || storedUserType === "rsm") && (
                                        <li onClick={handleMenuClick}>
                                            <NavLink to="networkzsmasm">Downstream</NavLink>
                                        </li>
                                    )}



                                </ul>
                            </div>

                        </div>
                    ) : (
                        <>
                        </>
                    )}

                    {storedUserType === 'Super Distributor' || storedUserType === 'Distributor' || storedUserType === "asm" || storedUserType === "zsm" || storedUserType === "rsm" ? (
                        <></>
                    ) : (
                        <>
                            <div className="MenuItem ">
                                <div className="card-header collapsed" id="headingOne" >
                                    <Link to="#" onClick={() => { changeOpen(!allAside.report, "report"); }}>
                                        <i>
                                            <svg x={0} y={0} viewBox="0 0 512 512">
                                                <path d="M 77.109375 401.664062 L 55.164062 401.664062 C 24.746094 401.664062 0 426.414062 0 456.832031 L 0 497 C 0 505.285156 6.714844 512 15 512 L 79.691406 512 C 78.023438 507.304688 77.109375 502.257812 77.109375 497 Z M 77.109375 401.664062 M 184.21875 321.332031 L 162.277344 321.332031 C 131.859375 321.332031 107.109375 346.082031 107.109375 376.5 L 107.109375 497 C 107.109375 505.285156 113.824219 512 122.109375 512 L 186.800781 512 C 185.136719 507.304688 184.21875 502.257812 184.21875 497 Z M 184.21875 321.332031 M 509.054688 150.671875 L 401.941406 6.070312 C 399.109375 2.253906 394.640625 0 389.886719 0 C 385.132812 0 380.660156 2.253906 377.832031 6.070312 L 270.722656 150.671875 C 267.351562 155.222656 266.832031 161.289062 269.378906 166.347656 C 271.929688 171.40625 277.109375 174.601562 282.777344 174.601562 L 321.332031 174.601562 L 321.332031 497 C 321.332031 505.285156 328.046875 512 336.332031 512 L 443.441406 512 C 451.726562 512 458.441406 505.285156 458.441406 497 L 458.441406 174.601562 L 497 174.601562 C 502.664062 174.601562 507.847656 171.40625 510.394531 166.347656 C 512.945312 161.289062 512.425781 155.222656 509.054688 150.671875 Z M 509.054688 150.671875 M 291.332031 241 L 269.386719 241 C 238.96875 241 214.21875 265.746094 214.21875 296.164062 L 214.21875 497 C 214.21875 505.285156 220.9375 512 229.21875 512 L 293.910156 512 C 292.246094 507.304688 291.332031 502.257812 291.332031 497 Z M 291.332031 241" />
                                            </svg>
                                        </i>
                                        <span>Report</span>
                                        <i className="ic">
                                            <svg x={0} y={0} viewBox="0 0 7 11">
                                                <path d="M6.783,6.060 L2.231,10.803 C1.941,11.104 1.472,11.104 1.182,10.803 C0.893,10.501 0.893,10.012 1.182,9.710 L5.210,5.514 L1.182,1.317 C0.893,1.015 0.893,0.526 1.182,0.224 C1.472,-0.077 1.941,-0.077 2.231,0.224 L6.783,4.967 C6.928,5.118 7.000,5.316 7.000,5.514 C7.000,5.711 6.927,5.909 6.783,6.060 Z" />
                                            </svg>
                                        </i>
                                    </Link>
                                </div>
                                <div id="collapseOne" className={`SubmenuItems collapse ${allAside.report ? "mm-show" : "extra"}`} >
                                    <ul>
                                        <li onClick={handleMenuClick}>
                                            <NavLink to="wallet-report">Wallet Ledger Report</NavLink>
                                        </li>
                                        {/* <li onClick={handleMenuClick}>
                                    <NavLink to="recharge_history">Recharge Report</NavLink>
                                </li>
                                <li onClick={handleMenuClick}>
                                    <NavLink to="search_recharge_history">Search Recharge Transaction</NavLink>
                                </li> */}

                                        {storedUserType === 'Super Distributor' || storedUserType === 'Distributor' || storedUserType === "asm" || storedUserType === "zsm" || storedUserType === "rsm" ? (
                                            <></>
                                        ) : (
                                            <>
                                                <li onClick={handleMenuClick}>
                                                    <NavLink to="aeps-wallet">AEPS Wallet Report</NavLink>
                                                </li>
                                            </>

                                        )}

                                        <li onClick={handleMenuClick}>
                                            <NavLink to="dmt_report_search">DMT Report</NavLink>
                                        </li>

                                        <li onClick={handleMenuClick}>
                                            <NavLink to="aeps-transaction-report">AEPS Transaction Report</NavLink>
                                        </li>

                                        <li onClick={handleMenuClick}>
                                            <NavLink to="aadhaarpay_pay_report">Aadhaar Pay Report</NavLink>
                                        </li>


                                        <li onClick={handleMenuClick}>
                                            <NavLink to="airtel-cms-report">Airtel CMS Report</NavLink>
                                        </li>

                                        <li onClick={handleMenuClick}>
                                            <NavLink to="Aeps_report">AEPS Payout Report</NavLink>
                                        </li>
                                        <li onClick={handleMenuClick}>
                                            <NavLink to="utility-report">Utility Report</NavLink>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </>
                    )}




                    <div className="MenuItem ">
                        <div className="card-header collapsed" id="headingSettings" data-toggle="collapse" data-target="#collapseSettings" aria-expanded="false" aria-controls="collapseSettings">
                            <Link href="#" onClick={() => { changeOpen(!allAside.payment, "payment"); }}>
                                <img src={paymentIcon} alt="" style={{ width: '16px', height: '22px', marginRight: '10px' }} />
                                <span>Payments</span>
                                <i className="ic">
                                    <svg x={0} y={0} viewBox="0 0 7 11">
                                        <path d="M6.783,6.060 L2.231,10.803 C1.941,11.104 1.472,11.104 1.182,10.803 C0.893,10.501 0.893,10.012 1.182,9.710 L5.210,5.514 L1.182,1.317 C0.893,1.015 0.893,0.526 1.182,0.224 C1.472,-0.077 1.941,-0.077 2.231,0.224 L6.783,4.967 C6.928,5.118 7.000,5.316 7.000,5.514 C7.000,5.711 6.927,5.909 6.783,6.060 Z" />
                                    </svg>
                                </i>
                            </Link>
                        </div>
                        <div id="collapseSettings" className={`SubmenuItems collapse ${allAside.payment ? "mm-show" : "extra"}`} aria-labelledby="headingSettings" data-parent="#accordion">
                            <ul>
                                {/* <li onClick={handleMenuClick}>
                                    <NavLink to="company-bank-details">Company Bank Details</NavLink>
                                </li>
                                <li onClick={handleMenuClick}>
                                    <NavLink to="add-payment-request">Add Payment Request</NavLink>
                                </li>
                                <li onClick={handleMenuClick}>
                                    <NavLink to="payment-request-to-company">Payment request to company </NavLink>
                                </li > */}

                                {/* {distributer && <><li onClick={handleMenuClick}>
                                    <NavLink to="payment-request-by-member">Payment Requested By Member</NavLink>
                                </li>
                                </>} */}


                                {retailer &&
                                    <>
                                        {/* <li onClick={handleMenuClick}>
                                            <NavLink to="payment-request-by-member">Payment Requested By Member</NavLink>
                                        </li> */}

                                        <li onClick={handleMenuClick}>
                                            <NavLink to="company-bank-details">Company Bank Details</NavLink>
                                        </li>
                                        <li onClick={handleMenuClick}>
                                            <NavLink to="add-payment-request">Add Payment Request</NavLink>
                                        </li>
                                        <li onClick={handleMenuClick}>
                                            <NavLink to="payment-request-to-company">Payment request to company </NavLink>
                                        </li >
                                        <li onClick={handleMenuClick}>
                                            <NavLink to="payment-request-to-distributor">Payment request to ZSM/ASM/SD/DS history</NavLink>
                                        </li>
                                    </>}
                                {distributer && <>
                                    <li onClick={handleMenuClick}>
                                        <NavLink to="company-bank-details">Company Bank Details</NavLink>
                                    </li>
                                    <li onClick={handleMenuClick}>
                                        <NavLink to="add-payment-request">Add Payment Request</NavLink>
                                    </li>
                                    <li onClick={handleMenuClick}>
                                        <NavLink to="payment-request-to-company">Payment request to company </NavLink>
                                    </li >
                                    <li onClick={handleMenuClick}>
                                        <NavLink to="payment-request-to-distributor">Payment request to  ZSM/ASM/SD history</NavLink>
                                    </li>
                                    <li onClick={handleMenuClick}>
                                        <NavLink to="payment-request-to-distributor/super-distributor-history">Payment Requested By Member</NavLink>
                                    </li>
                                </>}


                                {(superdistributer || asm || zsm || rsm) && <>
                                    {/* <li onClick={handleMenuClick}>
                                        <NavLink to="payment-request-to-distributor">Payment request to Distributor / Super distributor history</NavLink>
                                    </li> */}

                                    <li onClick={handleMenuClick}>
                                        <NavLink to="company-bank-details">Company Bank Details</NavLink>
                                    </li>

                                    <li onClick={handleMenuClick}>
                                        <NavLink to="add-payment-request">Add Payment Request</NavLink>
                                    </li>
                                    <li onClick={handleMenuClick}>
                                        <NavLink to="payment-request-to-company">Payment request to company </NavLink>
                                    </li >
                                    <li onClick={handleMenuClick}>
                                        <NavLink to="payment-request-to-distributor/super-distributor-history">Payment Requested By Member</NavLink>
                                    </li>
                                    {superdistributer && (
                                        <li onClick={handleMenuClick}>
                                            <NavLink to="payment-request-to-distributor">Payment request to ZSM/ASM</NavLink>
                                        </li>
                                    )}
                                    {asm && (
                                        <li onClick={handleMenuClick}>
                                            <NavLink to="payment-request-to-distributor">Payment request to ZSM</NavLink>
                                        </li>
                                    )}
                                    {rsm && (
                                        <li onClick={handleMenuClick}>
                                            <NavLink to="payment-request-to-distributor">Payment request to ZSM/ASM</NavLink>
                                        </li>
                                    )}
                                </>}
                                {storedUserType === 'Super Distributor' || storedUserType === 'Distributor' || storedUserType === "asm" || storedUserType === "zsm" || storedUserType === "rsm" ? (
                                    <></>
                                ) : (
                                    <>

                                        <li onClick={handleMenuClick}>
                                            <NavLink to="aeps-wallet-transfer">AEPS Wallet Transfer</NavLink>
                                        </li>
                                        <li onClick={handleMenuClick}>
                                            <NavLink to="aeps-payout-details">AEPS Settlement</NavLink>
                                        </li>
                                    </>
                                )}

                            </ul>
                        </div>
                    </div>


                    {storedUserType === 'Super Distributor' || storedUserType === 'Distributor' || storedUserType === "asm" || storedUserType === "zsm" || storedUserType === "rsm" ? (
                        <></>
                    ) : (
                        <>
                            <div className="MenuItem ">
                                <div className="card-header collapsed" id="headingSettings" data-toggle="collapse" data-target="#collapseSettings" aria-expanded="false" aria-controls="collapseSettings">
                                    <Link href="#" onClick={() => { changeOpen(!allAside.package, "package"); }}>
                                        {/* <img src={paymentIcon} alt="" style={{ width: '16px', height: '22px', marginRight: '10px' }} /> */}
                                        <FiPackage style={{ width: '16px', height: '22px', marginRight: '10px' }} />
                                        <span>Package</span>
                                        <i className="ic">
                                            <svg x={0} y={0} viewBox="0 0 7 11">
                                                <path d="M6.783,6.060 L2.231,10.803 C1.941,11.104 1.472,11.104 1.182,10.803 C0.893,10.501 0.893,10.012 1.182,9.710 L5.210,5.514 L1.182,1.317 C0.893,1.015 0.893,0.526 1.182,0.224 C1.472,-0.077 1.941,-0.077 2.231,0.224 L6.783,4.967 C6.928,5.118 7.000,5.316 7.000,5.514 C7.000,5.711 6.927,5.909 6.783,6.060 Z" />
                                            </svg>
                                        </i>
                                    </Link>
                                </div>
                                <div id="collapseSettings" className={`SubmenuItems collapse ${allAside.package ? "mm-show" : "extra"}`} aria-labelledby="headingSettings" data-parent="#accordion">
                                    <ul>
                                        <li onClick={handleMenuClick}>
                                            <NavLink to="package-list">Package List</NavLink>
                                        </li>
                                        <li onClick={handleMenuClick}>
                                            <NavLink to="package-history">Package History</NavLink>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                    {storedUserType === 'Super Distributor' || storedUserType === 'Distributor' || storedUserType === "asm" || storedUserType === "zsm" || storedUserType === "rsm" ? (
                        <></>
                    ) : (
                        <>
                            <div className="MenuItem ">
                                <div className="card-header card-header-header" onClick={(e) => { changAllAside(e) }}>
                                    <NavLink to="pan-service">
                                        <i>
                                            <svg viewBox="0 0 384 428.8">
                                                <path d="M302.6,214.2c0.1-61.9-50.1-112.2-112-112.3C128.6,101.9,78.3,152,78.3,214s50.1,112.2,112,112.3c33.2,0,64.7-14.6,86-40 C293.2,266,302.5,240.5,302.6,214.2z M380.3,38.8L336,0v14.4c0,4.4-3.6,8-8,8H56c-30.9,0-56,25.1-56,56v200h32v-184c0-22.1,17.9-40,40-40h256c4.4,0,8,3.6,8,8 v20.7L380.3,38.8z M384,150.4h-32v184c0,22.1-17.9,40-40,40H56c-4.4,0-8-3.6-8-8v-20.7L3.7,390L48,428.8v-14.4c0-4.4,3.6-8,8-8h272 c30.9,0,56-25.1,56-56V150.4z M234.9,158H200c6.1,4.9,10.6,11.8,11.8,21.6h23.1v10.6h-22.8c-2.1,21.6-19.3,35.3-46.3,36.2 c16.5,17.1,34.5,38.7,52.3,60.5h-20.3c-16.5-19.9-31.9-38.3-51.8-59.4v-13.1h11.6c22.8,0,35.1-9.5,36.6-24.1h-48.2v-10.6h47.6 c-3-12.3-14.2-18.4-32.6-18.4h-15v-13.8h89.1V158z" />
                                            </svg>
                                        </i>
                                        <span>Pan Services</span>
                                    </NavLink>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="MenuItem ">
                        <div className="card-header collapsed headingSettings-2" id="headingSettings" data-toggle="collapse" data-target="#collapseSettings" aria-expanded="false" aria-controls="collapseSettings">
                            <Link href="#" onClick={() => { changeOpen(!allAside.disput, "disput"); }}>
                                <MdOutlineDisplaySettings />
                                <span>Help & Support</span>
                                <i className="ic">
                                    <svg x={0} y={0} viewBox="0 0 7 11">
                                        <path d="M6.783,6.060 L2.231,10.803 C1.941,11.104 1.472,11.104 1.182,10.803 C0.893,10.501 0.893,10.012 1.182,9.710 L5.210,5.514 L1.182,1.317 C0.893,1.015 0.893,0.526 1.182,0.224 C1.472,-0.077 1.941,-0.077 2.231,0.224 L6.783,4.967 C6.928,5.118 7.000,5.316 7.000,5.514 C7.000,5.711 6.927,5.909 6.783,6.060 Z" />
                                    </svg>
                                </i>
                            </Link>
                        </div>
                        <div id="collapseSettings" className={`SubmenuItems collapse ${allAside.disput ? "mm-show" : "extra"}`} aria-labelledby="headingSettings" data-parent="#accordion">
                            <ul>
                                <li onClick={handleMenuClick}>
                                    <NavLink to="add-ticket">Add Ticket</NavLink>
                                </li>
                                <li onClick={handleMenuClick}>
                                    <NavLink to="list-tickets">List Tickets</NavLink>
                                </li>
                                <li onClick={handleMenuClick}>
                                    <NavLink to="open-dispute">Pending Tickets</NavLink>
                                </li>
                                <li onClick={handleMenuClick}>
                                    <NavLink to="clossing-Dispute">Closed Tickets</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {/* <div className="MenuItem ">
                        <div className="card-header">
                            <Link to="complain-list">
                                <i>
                                    <svg x={0} y={0} viewBox="0 0 22 16">
                                        <path d="M19.421,12.936 L19.421,15.705 L16.464,13.810 C15.899,13.890 15.357,13.928 14.867,13.928 C10.892,13.928 7.778,12.022 7.778,9.590 C7.778,7.158 10.892,5.252 14.867,5.252 C18.842,5.252 21.999,7.158 21.999,9.590 C21.999,10.911 21.065,12.115 19.421,12.936 ZM12.289,8.970 L11.000,8.970 L11.000,10.210 L12.289,10.210 L12.289,8.970 ZM15.511,8.970 L14.222,8.970 L14.222,10.210 L15.511,10.210 L15.511,8.970 ZM18.776,8.970 L17.488,8.970 L17.488,10.210 L18.776,10.210 L18.776,8.970 ZM11.000,4.013 L9.711,4.013 L9.711,5.156 C7.968,6.040 6.790,7.369 6.542,8.950 C6.234,8.931 5.915,8.900 5.579,8.852 L2.622,10.748 L2.622,7.978 C0.977,7.157 0.001,5.953 0.001,4.632 C0.001,2.200 3.158,0.295 7.133,0.295 C10.773,0.295 13.661,1.899 14.127,4.036 C12.991,4.096 11.945,4.307 11.000,4.620 L11.000,4.013 ZM4.555,4.013 L3.266,4.013 L3.266,5.252 L4.555,5.252 L4.555,4.013 ZM7.778,4.013 L6.489,4.013 L6.489,5.252 L7.778,5.252 L7.778,4.013 Z" />
                                    </svg>
                                </i>
                                <span>Complain</span>
                            </Link>
                        </div>
                    </div> */}
                    <div className="MenuItem ">
                        <div className="card-header collapsed" id="headingSettings" data-toggle="collapse" data-target="#collapseSettings" aria-expanded="false" aria-controls="collapseSettings">
                            <Link href="#" onClick={() => { changeOpen(!allAside.setting, "setting"); }}>
                                <i>
                                    <svg x={0} y={0} viewBox="0 0 16 16" className="sm-svg default-svg">
                                        <path d="M15.920,7.112 C15.895,6.887 15.633,6.718 15.407,6.718 C14.675,6.718 14.026,6.287 13.753,5.621 C13.475,4.940 13.655,4.144 14.200,3.643 C14.372,3.486 14.392,3.223 14.248,3.040 C13.874,2.564 13.448,2.133 12.983,1.760 C12.801,1.614 12.534,1.634 12.377,1.809 C11.901,2.337 11.046,2.534 10.385,2.257 C9.698,1.968 9.264,1.270 9.307,0.521 C9.320,0.285 9.149,0.081 8.915,0.053 C8.318,-0.016 7.716,-0.018 7.118,0.048 C6.887,0.074 6.715,0.274 6.723,0.506 C6.749,1.248 6.310,1.934 5.629,2.213 C4.977,2.480 4.128,2.286 3.653,1.762 C3.496,1.590 3.233,1.569 3.050,1.711 C2.572,2.088 2.136,2.518 1.758,2.991 C1.611,3.175 1.633,3.441 1.806,3.599 C2.362,4.103 2.541,4.905 2.253,5.595 C1.977,6.253 1.295,6.676 0.515,6.676 C0.262,6.668 0.082,6.839 0.054,7.069 C-0.016,7.670 -0.017,8.283 0.050,8.889 C0.076,9.114 0.345,9.282 0.574,9.282 C1.269,9.264 1.937,9.695 2.217,10.378 C2.496,11.060 2.317,11.855 1.770,12.356 C1.600,12.514 1.578,12.776 1.722,12.959 C2.093,13.433 2.519,13.863 2.985,14.240 C3.168,14.387 3.434,14.367 3.593,14.191 C4.071,13.662 4.926,13.466 5.584,13.743 C6.273,14.032 6.706,14.730 6.664,15.479 C6.650,15.715 6.823,15.920 7.056,15.946 C7.361,15.982 7.668,16.000 7.976,16.000 C8.268,16.000 8.560,15.984 8.852,15.951 C9.084,15.926 9.255,15.726 9.248,15.493 C9.221,14.752 9.660,14.066 10.340,13.787 C10.997,13.518 11.843,13.715 12.318,14.238 C12.475,14.410 12.736,14.431 12.920,14.288 C13.398,13.913 13.832,13.483 14.212,13.009 C14.359,12.825 14.339,12.559 14.164,12.401 C13.609,11.897 13.428,11.094 13.717,10.405 C13.988,9.756 14.645,9.321 15.351,9.321 L15.449,9.323 C15.678,9.342 15.889,9.165 15.917,8.931 C15.987,8.329 15.988,7.718 15.920,7.112 ZM7.998,10.685 C6.529,10.685 5.335,9.488 5.335,8.017 C5.335,6.545 6.529,5.348 7.998,5.348 C9.467,5.348 10.661,6.545 10.661,8.017 C10.661,9.488 9.467,10.685 7.998,10.685 Z" />
                                    </svg>
                                </i>
                                <span>Settings</span>
                                <i className="ic">
                                    <svg x={0} y={0} viewBox="0 0 7 11">
                                        <path d="M6.783,6.060 L2.231,10.803 C1.941,11.104 1.472,11.104 1.182,10.803 C0.893,10.501 0.893,10.012 1.182,9.710 L5.210,5.514 L1.182,1.317 C0.893,1.015 0.893,0.526 1.182,0.224 C1.472,-0.077 1.941,-0.077 2.231,0.224 L6.783,4.967 C6.928,5.118 7.000,5.316 7.000,5.514 C7.000,5.711 6.927,5.909 6.783,6.060 Z" />
                                    </svg>
                                </i>
                            </Link>
                        </div>
                        <div id="collapseSettings" className={`SubmenuItems collapse ${allAside.setting ? "mm-show" : "extra"}`} aria-labelledby="headingSettings" data-parent="#accordion">
                            <ul>

                                <li onClick={handleMenuClick}>
                                    <NavLink to="billing_Address">Billing Address</NavLink>
                                </li>

                                <li onClick={handleMenuClick}>
                                    <NavLink to="change_password">Password Change</NavLink>
                                </li>
                                <li onClick={handleMenuClick}>
                                    <NavLink to="change_txnpassword">Change Tpin</NavLink>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="MenuItem ">

                    </div>

                    <div className="MenuItem ">


                    </div>

                </div></div > <div id="mCSB_1_scrollbar_vertical" className="mCSB_scrollTools mCSB_1_scrollbar mCS-minimal-dark mCSB_scrollTools_vertical" style={{ display: 'block' }}><div className="mCSB_draggerContainer"><div id="mCSB_1_dragger_vertical" className="mCSB_dragger" style={{ position: 'absolute', minHeight: 50, height: 154, top: 0, display: 'block', maxHeight: '291.6px' }}><div className="mCSB_dragger_bar" style={{ lineHeight: 50 }} /></div><div className="mCSB_draggerRail" /></div></div></aside >
            <div id="myDIV" onClick={clickoVer}></div>
        </>
    )
}
export default AsideAdmin


import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Card, Form, Row, Col, Modal } from 'react-bootstrap';
import { FaEye, FaChevronDown, FaChevronUp, FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Popconfirm } from 'antd';
import { Pagination } from "antd"
import OtpVerificationPage from './UserlistOtp';

// Import necessary API functions
import { getDownstreamList, fundtransferToRefer, confirmReverseTransfer, Getprofile, getDownstreamListReport, WalletsShow } from '../../../api/login/Login';
import Loader from '../../../common/loader/Loader';
import "./Userlistzsm2.css"
import { SubUserTable } from './SubUserTable';


function Userlistzsm({ walletShowHeader, position }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(10);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [expandedUserId, setExpandedUserId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const [transferAmount, setTransferAmount] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [walletData, setWalletData] = useState({});

    const [filterData, setFilterData] = useState({
        userId: '',
        name: '',
        email: '',
        mobile: '',
        is_approved: '',
        refer_id: '',
        userType: '' // New filter for user type
    });

    const [isTransferValid, setIsTransferValid] = useState(false);
    const storedUserType = localStorage.getItem('userType');
    const storedUserTypeId = localStorage.getItem('userTypeId');
    const [showTpinModal, setShowTpinModal] = useState(false);
    const [showReverseTpinModal, setShowReverseTpinModal] = useState(false);
    const [tpin, setTpin] = useState('');
    const [tpinError, setTpinError] = useState('');
    const [showReverseTransferModal, setShowReverseTransferModal] = useState(false);
    const [reverseTransferAmount, setReverseTransferAmount] = useState('');
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [locationError, setLocationError] = useState(null);
    const [maskedTpin, setMaskedTpin] = useState('');
    const [walletlockdata, setLockWAletData] = useState({});
    const [isPopconfirmOpen, setIsPopconfirmOpen] = useState(false);
    const [storetoken, setStoreToken] = useState();
    const [isGettingLocation, setIsGettingLocation] = useState(false);
    const tableRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (tableRef.current) {
                const { top } = tableRef.current.getBoundingClientRect();
                if (top <= 0) {
                    tableRef.current.classList.add('sticky-header');
                } else {
                    tableRef.current.classList.remove('sticky-header');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    useEffect(() => {
        fetchData();
        walletShow();
        getDataProfile();
    }, [currentPage, count]);

    const fetchData = async (page = currentPage, size = pageSize) => {
        setLoading(true);
        try {
            const response = await getDownstreamList(page - 1, size, '', filterData);
            setData(response?.data?.data || []);
            setTotalCount(response?.data?.totalCount || 0);
            setCurrentPage(page);
            setPageSize(size);
        } catch (error) {
            console.error('Error fetching users:', error);
            toast.error('Failed to fetch users');
            setData([]);
            setTotalCount(0);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
        walletShow();
        getDataProfile();
    }, [currentPage, pageSize]);

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
        setPageSize(pageSize);
        fetchData(page, pageSize);
    };

    const handleTransferClick = async (user) => {
        setSelectedUser(user);
        
        setShowTransferModal(true);
    };

    //   const handleUserClick = async (userId, page = 1, pageSize = 10) => {
    //     setLoading(true);
    //     try {
    //       const response = await getDownstreamList(page - 1, pageSize, userId);
    //       if (response.data.statusCode == "200") {
    //         return {
    //           data: response.data.data || [],
    //           totalCount: response.data.totalCount || 0
    //         };
    //       } else {
    //         toast.error(response.data.message || 'Failed to fetch sub-users');
    //       }
    //     } catch (error) {
    //       console.error('Error fetching sub-users:', error);
    //       toast.error('Failed to fetch sub-users. Please try again.');
    //     } finally {
    //       setLoading(false);
    //     }
    //     return { data: [], totalCount: 0 };
    //   };
    const handleUserClick = async (userId, page = 1, pageSize = 10) => {
        setLoading(true);
        try {
            const response = await getDownstreamList(page - 1, pageSize, userId);
            if (response.data.statusCode == "200") {
                const updatedData = data.map(user => {
                    if (user._id === userId) {
                        return {
                            ...user,
                            subUsers: response.data.data || [],
                            subUsersTotalCount: response.data.totalCount || 0
                        };
                    }
                    return user;
                });
                setData(updatedData);
                return {
                    data: response.data.data || [],
                    totalCount: response.data.totalCount || 0
                };
            } else {
                toast.error(response.data.message || 'Failed to fetch sub-users');
            }
        } catch (error) {
            console.error('Error fetching sub-users:', error);
            toast.error('Failed to fetch sub-users. Please try again.');
        } finally {
            setLoading(false);
        }
        return { data: [], totalCount: 0 };
    };



    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilterData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        setPage(0);
        fetchData();
    };

    const resetFilter = () => {
        setFilterData({
            userId: '',
            name: '',
            email: '',
            mobile: '',
            is_approved: '',
            refer_id: '',
            userType: ''
        });
        setCurrentPage(1);
        fetchData();
    };

    const walletShow = async () => {
        try {
            const res = await WalletsShow();
            setWalletData(res?.data?.data);
        } catch (error) {
            console.error('Error fetching wallet data:', error);
        }
    };

    const getDataProfile = async () => {
        try {
            const res = await Getprofile();
            setLockWAletData(res?.data?.data);
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };

    const getCurrentLocation = () => {
        return new Promise((resolve, reject) => {
            if (latitude && longitude) {
                resolve(true);
            } else if (position.latitude && position.longitude) {
                setLatitude(position.latitude);
                setLongitude(position.longitude);
                setLocationError(null);
                resolve(true);
            } else if ("geolocation" in navigator) {
                setIsGettingLocation(true);
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLatitude(position.coords.latitude);
                        setLongitude(position.coords.longitude);
                        setLocationError(null);
                        setIsGettingLocation(false);
                        resolve(true);
                    },
                    (error) => {
                        console.error("Geolocation error:", error);
                        setLocationError("Unable to retrieve your location. Please ensure location services are enabled.");
                        setIsGettingLocation(false);
                        reject(error);
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 10000,
                        maximumAge: 0
                    }
                );
            } else {
                setLocationError("Geolocation is not supported by your browser");
                reject(new Error("Geolocation not supported"));
            }
        });
    }

    const handleTransferAmountChange = (e) => {
        const amount = e.target.value;
        setTransferAmount(amount);
        setIsTransferValid(Number(amount) >= 100 && Number(amount) <= Number(walletData?.main_wallet - walletlockdata?.locking_amt));
    };

    const handleReverseTransferClick = (user) => {
        setSelectedUser(user);
        // await getCurrentLocation();
        setShowReverseTransferModal(true);
    };

    const handleReverseTransferAmountChange = (e) => {
        const amount = e.target.value;
        setReverseTransferAmount(amount);
    };

    const handleTransferSubmit = async () => {
        try {
            await getCurrentLocation();

            if (locationError) {
                toast.error(locationError);
                return;
            }

            if (!latitude || !longitude) {
                toast.error("Location information is not available. Please try again.");
                return;
            }

            const response = await fundtransferToRefer({
                transferTo: selectedUser._id,
                amount: Number(transferAmount),
                tpin: tpin,
                trans_type: "transfer",
                lat: latitude,
                long: longitude
            });

            if (response.data.statusCode == "200") {
                toast.success('Money transferred successfully');
                setShowTpinModal(false);
                resetModalStates();
           
                walletShow();
                if (walletShowHeader) walletShowHeader();
                if (fetchData) fetchData();
            } else if (response.data.statusCode == "403") {
                setTpinError(response?.data?.message);
                toast.error(response?.data?.message);
            } else {
                toast.error(response.data.message || 'Transfer failed. Please try again.');
            }
        } catch (error) {
            console.error('Error transferring money:', error);
            if (error.name === 'GeolocationPositionError') {
                toast.error("Unable to access location. Please enable location services and try again.");
            } else {
                toast.error('Transfer failed. Please try again.');
            }
        }
    };

    const handleReverseTransferSubmit = async () => {
        try {
            await getCurrentLocation();

            if (locationError) {
                toast.error(locationError);
                return;
            }

            const response = await fundtransferToRefer({
                transferTo: selectedUser._id,
                amount: Number(reverseTransferAmount),
                tpin: tpin,
                trans_type: "refund",
                lat: latitude,
                long: longitude
            });

            if (response.data.statusCode == "200") {
                const token = response.data.data.token;
                setStoreToken(token);
                setShowReverseTpinModal(false);
                setShowOtpModal(true);
               
            } else if (response.data.statusCode == "403") {
                setTpinError(response?.data?.message);
                toast.error(response?.data?.message);
            } else {
                toast.error(response.data.message || 'Reverse transfer failed. Please try again.');
            }
        } catch (error) {
            console.error('Error reverse transferring money:', error);
            toast.error('Reverse transfer failed. Please try again.');
        }
    };

    // const resetModalStates = () => {
    //     setTransferAmount('');
    //     setReverseTransferAmount('');
    //     setTpin('');
    //     setMaskedTpin('');
    //     setTpinError('');
    //     setIsTransferValid(false);
    //     setIsPopconfirmOpen(false);
    // };

    const handleReportClick = (userId) => {
        navigate(`/user-report/${userId}`);
    };

    const handleReportClickmainreportasmzsm = (userId) => {
        navigate(`/main-report/${userId}`);
    };

    const renderUserRow = (user, index, level = 0, parentIndex = null) => {
        const indent = level * 20; // 20px indent for each level
        const hasSubUsers = user.subUsers && user.subUsers.length > 0;
        const isExpanded = expandedUserId === user._id;

        return (
            <React.Fragment key={user._id}>
                <tr className={level > 0 ? 'nested-row' : ''}>
                    <td style={{ paddingLeft: `${indent}px` }}>
                        {index + 1 + (page * count)}
                    </td>
                    <td>{user.refer_id}</td>
                    <td>{user.name}</td>
                    <td>{user.userType}</td>
                    <td className={user.is_approved ? 'approvedstatusindownstrip' : 'pendingstatusindownstrip'}>
                        {user.is_approved ? 'Approved' : 'Pending'}
                    </td>
                    <td>
                        <Button
                            variant="link"
                            onClick={() => handleUserClick(user._id, parentIndex)}
                            className="d-flex align-items-center"
                        >
                            View Downstream
                            {isExpanded ?
                                <FaChevronUp className="ms-1" /> :
                                <FaChevronDown className="ms-1" />
                            }
                        </Button>
                    </td>
                    <td>
                        <Button variant="outline-primary" size="sm" onClick={() => handleTransferClick(user)}>
                            Transfer Money
                        </Button>
                    </td>
                    <td>
                        <Button variant="outline-secondary" size="sm" onClick={() => handleReverseTransferClick(user)}>
                            Reverse Transfer
                        </Button>
                    </td>
                    <td>{user?.main_wallet || 0}</td>
                    <td>
                        <Button variant="link" onClick={() => handleReportClick(user._id)}>
                            <FaEye className="text-primary" />
                        </Button>
                    </td>
                    {/* <td>
                        <Button variant="link" onClick={() => handleReportClickmainreportasmzsm(user._id)}>
                            <FaEye className="text-primary" />
                        </Button>
                    </td> */}
                </tr>
                {isExpanded && hasSubUsers && (
                    <tr>
                        <td colSpan="10" className="p-0">
                            <Table className="mb-0">
                                <tbody>
                                    {user.subUsers.map((subUser, subIndex) =>
                                        renderUserRow(subUser, subIndex, level + 1, index)
                                    )}
                                </tbody>
                            </Table>
                        </td>
                    </tr>
                )}
                {isExpanded && (!hasSubUsers || user.subUsers.length === 0) && (
                    <tr>
                        <td colSpan="10">
                            <div className="text-center py-3">No downstream members available</div>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    };

    const resetModalStates = () => {
        setShowTransferModal(false);
        setShowTpinModal(false);
        setShowReverseTransferModal(false);
        setShowReverseTpinModal(false);
        setShowOtpModal(false);
        setTransferAmount('');
        setReverseTransferAmount('');
        setTpin('');
        setMaskedTpin('');
        setTpinError('');
        setIsTransferValid(false);
        setIsPopconfirmOpen(false);
        setSelectedUser(null);
    };
    return (
        <>
            {loading && <Loader />}
            <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Downstream Users</h1>

                {/* <Card className="shadow mb-4">
                    <Card.Header className="py-3 bg-primary text-white">
                        <h6 className="m-0 font-weight-bold">Filter</h6>
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={handleFilterSubmit}>
                            <Row>
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Refer ID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="refer_id"
                                            value={filterData.refer_id}
                                            onChange={handleFilterChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={filterData.name}
                                            onChange={handleFilterChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={filterData.email}
                                            onChange={handleFilterChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Mobile</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="mobile"
                                            value={filterData.mobile}
                                            onChange={handleFilterChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>User Type</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="userType"
                                            value={filterData.userType}
                                            onChange={handleFilterChange}
                                        >
                                            <option value="">All</option>
                                            {storedUserType === "zsm" && <option value="asm">ASM</option>}
                                            <option value="Super Distributor">Super Distributor</option>
                                            <option value="Distributor">Distributor</option>
                                            <option value="Retailer">Retailer</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={9} className="d-flex align-items-end justify-content-end">
                                    <Button variant="primary" type="submit" className="me-2">
                                        Apply Filter
                                    </Button>
                                    <Button variant="warning" onClick={resetFilter}>
                                        Reset
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card> */}

                {/* User List Table */}
                <Card className="shadow mb-4">
                    <Card.Header className="py-3 d-flex justify-content-between align-items-center bg-primary text-white">
                        <h6 className="m-0 font-weight-bold">User List</h6>
                    </Card.Header>
                    <Card.Body>
                        <div className="table-responsive">
                            <SubUserTable
                                users={data}
                                totalCount={totalCount}
                                onUserClick={handleUserClick}
                                onTransferClick={handleTransferClick}
                                onReverseTransferClick={handleReverseTransferClick}
                                onReportClick={handleReportClick}
                                onPageChange={handlePageChange}
                                initialPage={currentPage}
                                initialPageSize={pageSize}
                                handleReportClickmainreportasmzsm={handleReportClickmainreportasmzsm}
                            />
                        </div>
                        {totalCount > 0 && (
                            <Pagination
                                current={currentPage}
                                total={totalCount}
                                pageSize={count}
                                onChange={handlePageChange}
                                showSizeChanger={false}
                                className="mt-3 justify-content-end"
                            />
                        )}
                    </Card.Body>
                </Card>
            </div>

            {/* Transfer Modal */}
            <Modal show={showTransferModal} onHide={() => { setShowTransferModal(false); resetModalStates(); }}>
                <Modal.Header closeButton className='d-flex justify-content-between'>
                    <Modal.Title>Transfer Money</Modal.Title>
                    <span id="baldiv" style={{ marginLeft: '20%' }}>
                    Available Balance: {Math.max(0, (walletData?.main_wallet || 0) - (walletlockdata?.locking_amt || 0))}
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Refer ID</Form.Label>
                            <Form.Control type="text" value={selectedUser?.refer_id || ''} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={selectedUser?.name || ''} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control type="text" value={selectedUser?.mobile || ''} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter amount (minimum 100)"
                                required
                                value={transferAmount}
                                onChange={handleTransferAmountChange}
                            />
                            {!isTransferValid && transferAmount !== '' && (
                                <Form.Text className="text-danger">
                                    {Number(transferAmount) < 100
                                        ? 'Amount must be at least 100 rupees'
                                        : 'Insufficient balance'}
                                </Form.Text>
                            )}
                        </Form.Group>
                        <div className='text-center'>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    if (Number(transferAmount) < 100) {
                                        toast.error('Amount must be at least 100 rupees');
                                    } else {
                                        setShowTransferModal(false);
                                        setShowTpinModal(true);
                                    }
                                }}
                                disabled={!isTransferValid}
                            >
                                Transfer
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* TPIN Modal */}
            <Modal show={showTpinModal} onHide={() => { setShowTpinModal(false); resetModalStates(); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter TPIN</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>TPIN</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter your TPIN"
                                value={tpin}
                                onChange={(e) => {
                                    const newValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
                                    setTpin(newValue);
                                    setMaskedTpin('*'.repeat(newValue.length));
                                }}
                                maxLength={4}
                            />
                            {locationError && <Form.Text className="text-danger">{locationError}</Form.Text>}
                            {tpinError && <Form.Text className="text-danger">{tpinError}</Form.Text>}
                        </Form.Group>
                        <div className='text-center'>
                            <Popconfirm
                                title="Are you sure you want to transfer this money?"
                                onConfirm={handleTransferSubmit}
                                okText="Yes"
                                cancelText="No"
                                onCancel={() => setIsPopconfirmOpen(false)}
                                open={isPopconfirmOpen}
                                okButtonProps={{ disabled: tpin.length !== 4 || locationError }}
                            >
                                <Button onClick={() => setIsPopconfirmOpen(true)} variant="primary" disabled={tpin.length !== 4 || locationError}>
                                    Submit
                                </Button>
                            </Popconfirm>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Reverse Transfer Modal */}
            <Modal show={showReverseTransferModal} onHide={() =>{ setShowReverseTransferModal(false) ; resetModalStates(); }  }>
                <Modal.Header closeButton className='d-flex justify-content-between'>
                    <Modal.Title>Reverse Transfer</Modal.Title>
                    <span id="baldiv" style={{ marginLeft: '20%' }}>
                       Available Balance: {selectedUser?.main_wallet || 0}
                    </span>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Refer ID</Form.Label>
                            <Form.Control type="text" value={selectedUser?.refer_id || ''} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" value={selectedUser?.name || ''} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control type="text" value={selectedUser?.mobile || ''} readOnly />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter amount (minimum 100)"
                                required
                                value={reverseTransferAmount}
                                onChange={handleReverseTransferAmountChange}
                            />
                            {Number(reverseTransferAmount) > Number(selectedUser?.main_wallet) && (
                                <Form.Text className="text-danger">
                                    Insufficient balance
                                </Form.Text>
                            )}
                            {Number(reverseTransferAmount) < 100 && reverseTransferAmount !== '' && (
                                <Form.Text className="text-danger">
                                    Amount must be at least 100 rupees
                                </Form.Text>
                            )}
                        </Form.Group>
                        <div className='text-center'>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    setShowReverseTransferModal(false);
                                    setShowReverseTpinModal(true);
                                }}
                                disabled={Number(reverseTransferAmount) > Number(selectedUser?.main_wallet) || Number(reverseTransferAmount) < 100}
                            >
                                Reverse Transfer
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Reverse TPIN Modal */}
            <Modal show={showReverseTpinModal} onHide={() => {setShowReverseTpinModal(false);  resetModalStates();   }}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter TPIN for Reverse Transfer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>TPIN</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter your TPIN"
                                value={tpin}
                                onChange={(e) => {
                                    const newValue = e.target.value.replace(/[^0-9]/g, '').slice(0, 4);
                                    setTpin(newValue);
                                    setMaskedTpin('*'.repeat(newValue.length));
                                }}
                                maxLength={4}
                            />
                            {locationError && <Form.Text className="text-danger">{locationError}</Form.Text>}
                            {tpinError && <Form.Text className="text-danger">{tpinError}</Form.Text>}
                        </Form.Group>
                        <div className='text-center'>
                            <Popconfirm
                                title="Are you sure you want to reverse transfer this money?"
                                onConfirm={handleReverseTransferSubmit}
                                okText="Yes"
                                cancelText="No"
                                onCancel={() => setIsPopconfirmOpen(false)}
                                open={isPopconfirmOpen}
                                okButtonProps={{ disabled: tpin.length !== 4 || locationError }}
                            >
                                <Button onClick={() => setIsPopconfirmOpen(true)} variant="primary" disabled={tpin.length !== 4 || locationError}>
                                    Submit
                                </Button>
                            </Popconfirm>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* OTP Verification Modal */}
            <OtpVerificationPage
                open={showOtpModal}
                userId={storetoken}
                onClose={() => setShowOtpModal(false) }
                longitude={longitude}
                latitude={latitude}
                transferAmount={reverseTransferAmount}
                walletShowHeader={walletShowHeader}
                fetchData={ fetchData}
                resetModalStates={resetModalStates}
            />

            <ToastContainer />
        </>
    );
}

export default Userlistzsm;
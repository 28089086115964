import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Table, Card, Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { FaDownload, FaSort } from 'react-icons/fa';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Pagination } from 'antd';
import "./Userlist.css";
import { getDownstreamListAsmZsmReport, UseReportAll } from '../../../api/login/Login';
import Loader from '../../../common/loader/Loader';

function Mainwalltreportzsm() {
    const { userId } = useParams();
    const location = useLocation();
    const [reportData, setReportData] = useState([]);
    const [allReportData, setAllReportData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalamount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().substr(0, 10);
    };

    const initialFilterState = {
        user_id: window.localStorage.getItem('userIdToken'),
        id: userId, 
        count: itemsPerPage,
        page: 1,
        start_date: getCurrentDate(),
        end_date: getCurrentDate(),
        type: '',
        trans_type: '',
        order_id: '',
        txn_id: ''
    };

    const [filterValues, setFilterValues] = useState(initialFilterState);
    const [appliedFilters, setAppliedFilters] = useState(initialFilterState);

    const fetchReportData = async () => {
        try {
            setLoading(true);
            setError(null);
            
            const payload = {
                ...appliedFilters,
                page: currentPage,
                count: itemsPerPage
            };
            
            const response = await getDownstreamListAsmZsmReport(payload);
            
            if (response?.data?.data) {
                // Ensure we're setting an array, even if empty
                setReportData(Array.isArray(response.data.data.wallet) ? response.data.data.wallet : []);
                setTotalCount(response.data.data.total || 0);
                setTotalAmount(response.data.totalAmount || 0);

            } else {
                setReportData([]);
                setTotalCount(0);
                setTotalAmount(0);
                setError("No data available");
            }
        } catch (error) {
            console.error('Error fetching user report:', error);
            setError(error?.message || "Failed to fetch report data");
            setReportData([]);
            setTotalCount(0);
            setTotalAmount(0);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReportData();
    }, [userId, currentPage, itemsPerPage, appliedFilters]);

    const fetchAllReportData = async () => {
        try {
            setLoading(true);
            setError(null);
            
            const response = await UseReportAll({
                ...appliedFilters,
                page: 1,
                count: totalCount || 1000 // Fallback count if totalCount is 0
            });
            
            if (response?.data?.data) {
                setAllReportData(Array.isArray(response.data.data) ? response.data.data : []);
            } else {
                setAllReportData([]);
            }
        } catch (error) {
            console.error('Error fetching all user report data:', error);
            setError(error?.message || "Failed to fetch complete report data");
            setAllReportData([]);
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilterValues(prevState => ({
            ...prevState,
            [name]: typeof value === 'string' ? value.trim() : value
        }));
    };

    const handleSearch = () => {
        setError(null);
        setAppliedFilters(filterValues);
        setCurrentPage(1);
    };

    const handleReset = () => {
        const resetState = {
            ...initialFilterState,
            start_date: "",
            end_date: ""
        };
        setError(null);
        setFilterValues(resetState);
        setAppliedFilters(resetState);
        setCurrentPage(1);
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedAndFilteredData = useMemo(() => {
        // Ensure we're working with an array
        const dataToSort = Array.isArray(reportData) ? [...reportData] : [];
        
        if (sortConfig.key && dataToSort.length > 0) {
            return dataToSort.sort((a, b) => {
                // Handle null or undefined values
                const aVal = a[sortConfig.key] ?? '';
                const bVal = b[sortConfig.key] ?? '';
                
                if (aVal < bVal) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aVal > bVal) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return dataToSort;
    }, [reportData, sortConfig]);

    const handleExcelDownload = async (e) => {
        if (allReportData.length === 0) {
            e.preventDefault();
            await fetchAllReportData();
        }
    };

    if (loading) {
        return (
            <Container fluid className="px-4">
                <Loader />
            </Container>
        );
    }

    return (
        <Container fluid className="px-4 ur-container">
            <h1 className="mt-4 mb-4 ur-title">Transaction Report</h1>

            {error && (
                <Alert variant="danger" className="mb-4">
                    {error}
                </Alert>
            )}

            <Card className="mb-4 ur-card">
                <Card.Header className="ur-card-header">Filter</Card.Header>
                <Card.Body className="ur-card-body">
                    <Form>
                        <Row className="mb-3">
                            <Col md={3}>
                                <Form.Group controlId="startDate">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control 
                                        type="date" 
                                        name="start_date" 
                                        value={filterValues.start_date} 
                                        onChange={handleFilterChange}
                                        max={filterValues.end_date} 
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="endDate">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control 
                                        type="date" 
                                        name="end_date" 
                                        value={filterValues.end_date} 
                                        onChange={handleFilterChange}
                                        min={filterValues.start_date}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="type">
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control 
                                        as="select" 
                                        name="type" 
                                        value={filterValues.type} 
                                        onChange={handleFilterChange}
                                    >
                                        <option value="">Select Type</option>
                                        <option value="all">All</option>
                                        <option value="credit">Credit</option>
                                        <option value="debit">Debit</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            {/* <Col md={3}>
                                <Form.Group controlId="order_id">
                                    <Form.Label>Order Id</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="order_id" 
                                        value={filterValues.order_id} 
                                        onChange={handleFilterChange}
                                    />
                                </Form.Group>
                            </Col> */}
                            <Col md={3}>
                                <Form.Group controlId="txn_id">
                                    <Form.Label>Txn Id</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        name="txn_id" 
                                        value={filterValues.txn_id} 
                                        onChange={handleFilterChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={2} className="d-flex align-items-end">
                                <Button variant="primary" onClick={handleSearch}>Search</Button>
                            </Col>
                            <Col md={2} className="d-flex align-items-end">
                                <Button variant="light" onClick={handleReset}>Reset</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>

            <Card className="mb-4 ur-card">
                <Card.Header className="ur-card-header">
                    <Row className="align-items-center">
                        {/* <Col md={12} className="text-end">
                            {sortedAndFilteredData.length > 0 && (
                                <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="btn btn-success ur-download-btn"
                                    table="table-to-xls"
                                    filename="user_report"
                                    sheet="user_report"
                                    buttonText={<><FaDownload className="me-2" /> Download Excel</>}
                                    onClick={handleExcelDownload}
                                />
                            )}
                        </Col> */}
                    </Row>
                </Card.Header>
                <Card.Body className="ur-card-body">
                    <Table responsive striped bordered hover id="table-to-xls" className="ur-table">
                        <thead>
                            <tr>
                            <th onClick={() => requestSort('_id')} className="ur-th">S.No <FaSort /></th>
                <th onClick={() => requestSort('txn_id')} className="ur-th">Transaction Id <FaSort /></th>
                <th onClick={() => requestSort('order_id')} className="ur-th">Order ID <FaSort /></th>
                <th onClick={() => requestSort('amount')} className="ur-th">Amount <FaSort /></th>
                <th onClick={() => requestSort('userid')} className="ur-th">User ID <FaSort /></th>
                <th onClick={() => requestSort('trans_type')} className="ur-th">Transaction Type <FaSort /></th>
                <th onClick={() => requestSort('type')} className="ur-th">Type <FaSort /></th>
                <th onClick={() => requestSort('message')} className="ur-th">Message <FaSort /></th>
                <th onClick={() => requestSort('o_bal')} className="ur-th">Opening Balance <FaSort /></th>
                <th onClick={() => requestSort('c_bal')} className="ur-th">Closing Balance <FaSort /></th>
                <th onClick={() => requestSort('createdAt')} className="ur-th">Date <FaSort /></th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {sortedAndFilteredData.length > 0 ? (
                                sortedAndFilteredData.map((item, index) => (
                                    <tr key={item._id || index} className="ur-tr">
                                       <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                        <td>{item?.txn_id || ''}</td>
                        <td>{item?.order_id || ''}</td>
                        <td>{item?.amount || ""}</td>
                        <td>{item?.userid || ''}</td>
                        <td>{item?.trans_type || ''}</td>
                        <td>{item?.type || ''}</td>
                        <td>{item?.message || ''}</td>
                        <td>{item?.o_bal || 0}</td>
                        <td>{item?.c_bal || 0}</td>
                        <td>{item?.createdAt || ''}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        {error || "No Data Found"}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    
                    {sortedAndFilteredData.length > 0 && (
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <p className="ur-total-count mb-0">Total Count: {totalCount}</p>
                            <p className="ur-total-count mb-0">Total Amount: {totalamount}</p>
                            <Pagination
                                current={currentPage}
                                total={totalCount}
                                pageSize={itemsPerPage}
                                onChange={page => setCurrentPage(page)}
                                showSizeChanger={false}
                            />
                        </div>
                    )}
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Mainwalltreportzsm;
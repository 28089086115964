import { useEffect, useState } from "react";
import { Snackbar, Alert } from "@mui/material";

import img1 from '../../../../asesets/logo/finger_success.gif';
import { capturefingerprint } from "../../../../common/fingurePrintJs/FingurePrint";
import { bankResiter } from "../../../../api/login/Login";
import Loader from "../../../../common/loader/Loader";

import imgg1 from '../../../../asesets/Devices/iris.png';
import imgg2 from '../../../../asesets/Devices/mantra.png';
import imgg3 from '../../../../asesets/Devices/mantral1.png';
import imgg4 from '../../../../asesets/Devices/morpho.png';
import imgg5 from '../../../../asesets/Devices/morphol1.png';
import imgg6 from '../../../../asesets/Devices/precision.png';
import imgg7 from '../../../../asesets/Devices/sequgen.png';
import imgg8 from '../../../../asesets/Devices/startrek.png';
import imgg9 from '../../../../asesets/Devices/startrekl1.png';

const mockData = [
    { id: '1', image: imgg1 },
    { id: '2', image: imgg2 },
    { id: '3', image: imgg3 },
    { id: '4', image: imgg4 },
    { id: '5', image: imgg5 },
    { id: '6', image: imgg6 },
    { id: '7', image: imgg7 },
    { id: '8', image: imgg8 },
    { id: '9', image: imgg9 },
];

function AuthRegister({onDailyAuthComplete,apiAepsType }) {
    const [position, setPosition] = useState({ latitude: null, longitude: null });
    const [loader, setLoader] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const [initialAuth, setInitialAuth] = useState({
        accessmodetype: "APP",
        latitude: "",
        longitude: "",
        data: "",
        ipaddress: "",
        user_id: ""
    });

    useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                setPosition({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            });
        } else {
            showSnackbar('Geolocation is not available in your browser.', 'error');
        }
    }, []);

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({ open: true, message, severity });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };
    const captureFingerprint = () => {
        setLoader(true);
        try {
            console.log('Initializing fingerprint capture');
            new capturefingerprint('http://127.0.0.1:11100/rd/capture', handleFingerprintResult);
        } catch (error) {
            console.error('Error in captureFingerprint:', error);
            showSnackbar('Error capturing fingerprint', 'error');
            setLoader(false);
        }
    };
    
    const handleFingerprintResult = (data) => {
        setLoader(false);
        console.log('Received data:', data);
    
        if (!data) {
            console.error('No data received in handleFingerprintResult function');
            showSnackbar('Error: No data received from fingerprint capture', 'error');
            return;
        }
    
        if (typeof data !== 'object') {
            console.error('Invalid data type received:', typeof data);
            showSnackbar('Error: Invalid data received from fingerprint capture', 'error');
            return;
        }
    
        const prolog = data.pid_data;
        if (!prolog) {
            console.error('No pid_data in received data');
            showSnackbar('Error: Plase Connect L1 device', 'error');
            return;
        }
    
        try {
            var parser = new DOMParser();
            const XmlStr = prolog + "<bookz/>";
            var xmlz = parser.parseFromString(XmlStr, "application/xml");
    
            if (data.errCode === '0') {
                showSnackbar('Finger Capture Successful');
            } else if (data.errCode) {
                showSnackbar(`Error in finger capture: ${data.errCode}`, 'error');
                return;
            }
    
            if (data.httpSuccess === true) {
                const encodedXml = window.btoa((new XMLSerializer()).serializeToString(xmlz));
                dataPost(encodedXml);
            } else {
                showSnackbar('HTTP request was not successful', 'error');
            }
        } catch (error) {
            console.error('Error processing fingerprint data:', error);
            showSnackbar('Error processing fingerprint data', 'error');
        }
    };

    const extractPidData = (data) => {
        const pidDataStart = data.indexOf('<PidData>');
        const pidDataEnd = data.indexOf('</PidData>');
        if (pidDataStart !== -1 && pidDataEnd !== -1) {
            return data.substring(pidDataStart, pidDataEnd + 10); // +10 to include '</PidData>'
        }
        return null;
    };

    const dataPost = async (fingerprintData) => {
        const requestData = {
            ...initialAuth,
            latitude: position.latitude,
            longitude: position.longitude,
            data: fingerprintData,
            user_id: window.localStorage.getItem('userIdToken'),
            bank:apiAepsType,
        };
        
        try {
            setLoader(true);
            const response = await bankResiter(requestData);
            if (response?.data?.statusCode === 200) {
                showSnackbar('Bank registration successful', 'success');
                if(onDailyAuthComplete){
                    onDailyAuthComplete()  
                    }
            } else {
                showSnackbar(response?.data?.message || 'Bank registration failed', 'error');
            }
        } catch (error) {
            console.error('Error during bank registration:', error);
            showSnackbar('Error during bank registration', 'error');
        } finally {
            setLoader(false);
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div className="ContentArea">
                <div className="card">
                    <div className="card-header Register-header">
                        <span>Bank Register</span>
                    </div>
                    <div className="card-body">
                        <div className="form-row">
                            <div className="col-lg-6">
                                <div className="bankRegister">
                                    <button type="button" className="btn btn-success" onClick={captureFingerprint}>
                                        <img src={img1} alt="Capture Fingerprint" style={{ height: '526px' }} />
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    {mockData.map((item) => (
                                        <div className="col-lg-4 mt-2" key={item.id}>
                                            <div className="deviceImage">
                                                <img src={item.image} alt={`Device ${item.id}`} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default AuthRegister;
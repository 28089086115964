import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ReactFancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css';
import { Grid, Paper, Typography, Box, TextField, useMediaQuery, Alert } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles'; 
import { 
  Payment, CreditCard, ElectricBolt, LocalGasStation, PhoneAndroid, 
  Tv, Wifi, LocalShipping, School, LocalHospital, Build,
  ErrorOutline
} from '@mui/icons-material';

// Professional color palette
const colors = {
  primary: '#1976d2',
  background: '#f5f7fa',
  cardBg: '#ffffff',
  text: '#333333',
  iconBg: '#e3f2fd',
  error: '#f44336',
  warning: '#ff9800'
};

const ServiceItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  backgroundColor: colors.cardBg,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  borderRadius: '8px',
  border: '1px solid #e0e0e0',
  height: '100%',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: colors.iconBg,
  borderRadius: '50%',
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '48px',
  height: '48px',
  [theme.breakpoints.up('sm')]: {
    width: '56px',
    height: '56px',
  },
}));

const ErrorMessage = styled(Alert)(({ theme }) => ({
  margin: theme.spacing(2),
  width: '100%'
}));

const FallbackContent = ({ error, message }) => (
  <Box sx={{ p: 2, textAlign: 'center' }}>
    <ErrorOutline sx={{ fontSize: 48, color: colors.error, mb: 2 }} />
    <Typography variant="h6" gutterBottom>
      {message || "Something went wrong"}
    </Typography>
    {error && (
      <Typography variant="body2" color="text.secondary">
        {error.message}
      </Typography>
    )}
  </Box>
);

function ServiceGrid({ services = [], isInstantpaypresnt }) {
  const navigate = useNavigate();
  const [loadedImages, setLoadedImages] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);
  const [filteredServices, setFilteredServices] = useState([]);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  // Error boundary for the entire component
  useEffect(() => {
    try {
      if (!Array.isArray(services)) {
        throw new Error('Invalid services data received');
      }
      setError(null);
    } catch (err) {
      setError(err);
      console.error('ServiceGrid initialization error:', err);
    }
  }, [services]);

  const getIcon = (serviceName) => {
    try {
      const iconMap = {
        "Aeps Cash Deposit": Payment,
        "Credit Card": CreditCard,
        "Electricity": ElectricBolt,
        "DMT": Payment,
        "Gas": LocalGasStation,
        "Landline Postpaid": PhoneAndroid,
        "Fastag": LocalShipping,
        "DTH": Tv,
        "Broadband Postpaid": Wifi,
        "Water": Build,
        "Mobile Postpaid": PhoneAndroid,
        "Recharge": PhoneAndroid,
        "Aeps Bank Withdraw": Payment,
        "Aeps Adhaar pay": Payment,
        "CMS": Build,
        "Education Fees": School,
        "Quick Dhan": Payment,
        "NCMC Recharge": Payment,
        "Hospital and Pathology": LocalHospital,
        "Insurance": Build,
        "LPG Gas": LocalGasStation,
      };

      const Icon = iconMap[serviceName] || Build;
      return <Icon style={{ color: colors.primary, fontSize: isXs ? 20 : 24 }} />;
    } catch (error) {
      console.error('Icon rendering error:', error);
      return <Build style={{ color: colors.warning, fontSize: isXs ? 20 : 24 }} />;
    }
  };

  const routeMap = {
    "206": "/aeps-cash-deposit",
    "19": "/ccbill_payment_report",
    "18": "/electricity-bills",
    "33": "/mobile-reacharge",
    "13": "/dth-reacharge",
    "11": "/gas-bills",
    "10": "/postpaid-recharge",
    "5": "/Boardband",
    "35": "/airtel-cms",
    "12": "/landline-bills",
    "208": "/aeps-OnBoarded",
    "207": "/aeps-OnBoarded",
    "9": "/fastag-recharge",
    "188": "/money-transfer",
    "28": "/water-bills",
    "15": "/hospital-pathology",
    "3": "/educational-fees",
    "16": "/lpg-gasbill-payment",   
    "31": "/nmrc-paymentbill",   
    "36": "/quickdhan-payment",  
    "8": "/insurance-payment",  
    "17": "/muncipaltax-paymentbill",
    "29": "/metro-recharge",
    "2": "/loan-repayment",
    "22": "/life-insurance",
    "4": "/housing-society",
    "14": "/health-insurance-bill",
    "26": "/health-insurance",
    "30": "/clubs-associations",
    "21": "/cable-tv",
    "24": "/b2b",
    "6": "/subscription",
    "25": "/rent-bill",
    "216": "/recurringdeposite",
    "7": "/muncipaltax-paymentbill",
    "1": "/hospital-pathology",
    "27": "/donation",
  };

  const handleNavigation = (serviceId) => {
    try {
      const route = routeMap[serviceId] || "/Dashboard";
      navigate(route);
    } catch (error) {
      console.error('Navigation error:', error);
      setError(error);
      navigate("/Dashboard");
    }
  };

  const handleImageLoad = (serviceId) => {
    try {
      setTimeout(() => {
        setLoadedImages(prev => ({ ...prev, [serviceId]: true }));
      }, 1000);
    } catch (error) {
      console.error('Image load error:', error);
      // Don't set error state for image loading failures
    }
  };

  const handleImageError = (serviceId) => {
    console.error(`Failed to load image for service ${serviceId}`);
    // Remove the failed image from loaded images
    setLoadedImages(prev => {
      const newState = { ...prev };
      delete newState[serviceId];
      return newState;
    });
  };

  // Update filtered services whenever search term or services change
  useEffect(() => {
    try {
      if (!Array.isArray(services)) {
        setFilteredServices([]);
        return;
      }

      const filtered = services.filter(service => {
        try {
          if (!service || typeof service.service_name === 'undefined') {
            return false;
          }
          
          const serviceName = String(service.service_name || '');
          const search = String(searchTerm || '');
          
          return serviceName.localeCompare(search, undefined, { sensitivity: 'base' }) === 0 ||
                 serviceName.includes(search);
        } catch (err) {
          console.error('Service filtering error:', err);
          return false;
        }
      });

      setFilteredServices(filtered);
    } catch (err) {
      console.error('Filtering error:', err);
      setFilteredServices([]);
    }
  }, [services, searchTerm]);

  // If there's a critical error, show the error message
  if (error) {
    return (
      <ErrorMessage severity="error">
        <Typography variant="h6">Error Loading Services</Typography>
        <Typography variant="body2">{error.message}</Typography>
      </ErrorMessage>
    );
  }

  // If services is not an array, show a warning
  if (!Array.isArray(services)) {
    return (
      <ErrorMessage severity="warning">
        No services available at the moment. Please try again later.
      </ErrorMessage>
    );
  }

  return (
    <Box sx={{ backgroundColor: colors.background, padding: { xs: 2, sm: 3, md: 4 }, borderRadius: '12px' }}>
      <TextField
        variant="outlined"
        placeholder="Search by service name..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />

      {filteredServices.length === 0 && searchTerm && (
        <Alert severity="info" sx={{ mb: 2 }}>
          No services found matching your search.
        </Alert>
      )}

      <Grid container spacing={2}>
        {filteredServices.map((service) => {
          try {
            const serviceId = service?._id || '';
            const serviceName = service?.service_name || '';
            const serviceIcon = service?.icon || '';
            
            return (
              <Grid 
                item 
                xs={6}
                sm={4}
                md={3}
                lg={2}
                key={serviceId}
              >
                <ServiceItem 
                  elevation={0}
                  onClick={() => handleNavigation(serviceId)}
                  style={{ cursor: routeMap[serviceId] ? 'pointer' : 'default' }}
                >
                  <IconWrapper>
                    {serviceIcon ? (
                      loadedImages[serviceId] ? (
                        <img 
                          src={`https://api.paypanda.in/api/cloudinary/${serviceIcon}`} 
                          alt={serviceName}
                          className="ljhdfjdh"
                          onError={() => handleImageError(serviceId)}
                        />
                      ) : (
                        <img 
                          src={`https://api.paypanda.in/api/cloudinary/${serviceIcon}`} 
                          alt={serviceName}
                          onLoad={() => handleImageLoad(serviceId)}
                          onError={() => handleImageError(serviceId)}
                          style={{ display: 'none' }}
                        />
                      )
                    ) : (
                      getIcon(serviceName)
                    )}
                  </IconWrapper>
                  <Typography 
                    variant={isXs ? "caption" : "body2"} 
                    style={{ 
                      color: colors.text, 
                      fontWeight: 500,
                      fontSize: isXs ? '0.75rem' : '0.875rem',
                      lineHeight: 1.2,
                    }}
                  >
                    {serviceName || 'Unnamed Service'}
                  </Typography>
                </ServiceItem>
              </Grid>
            );
          } catch (error) {
            console.error('Service rendering error:', error);
            return null; // Skip rendering this service if there's an error
          }
        })}
      </Grid>
    </Box>
  );
}

export default ServiceGrid;
import { useEffect, useState } from "react"
import { aepsOnboard, UserPermissionAeps } from "../../../api/login/Login"
import CashDeposit from "../../../components/admin/cashDiposit/CashDeposit"
import AepsDipositPurchge from "./aepsDipositPurchage/AepsDipositPurchage"
import CasDepositTab from "./casDepositTab/CasDepositTab"
import PermissionAepsOnboarding from "../../../components/admin/aepsOnboarding/permissionAepsOnboarding/PermissionAepsOnboarding"
import SuggestOnboarding from "../../../components/admin/aepsOnboarding/suggetsOnboarding/SuggestOnboarding"
import BankPermission from "../../../components/admin/aepsOnboarding/bankPermission/BankPermission"
import AuthResister from "../../../components/admin/aepsOnboarding/authResister/AuthResister"
import DailyAuth from "../../../components/admin/aepsOnboarding/dailyAuth/DailyAuth"
import { toast, ToastContainer } from "react-toastify"
import Bank4aepsverify from "../aepsOnboarding/Bank4aepsverify"
import { toastSuccessMessage } from "../../../components/compeleteRegister/ToastShare"
import { Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../../common/loader/Loader"
import {  Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button, Modal } from '@mui/material';

function CashDepositPage({ profiledata }) {
    const [loading, setLoading] = useState(false);
    const [aepsType, setAepsType] = useState('Aeps2');
    const apiAepsType = aepsType === 'Aeps2' ? 'aeps2' : 'aeps4';
    const [bankverify, setbankverify] = useState(false);
    const [bankServerDown, setBankServerDown] = useState(false);
    const [merchnadecode, setMerchantCode] = useState(null);
    const [aepsData, setAepsData] = useState({
        Aeps2: {
            isPlanPurchased: false,
            isOnboarded: false,
            hasBankPermission: false,
            isAuthRegistered: false,
            hasDailyAuth: false
        },
        Aeps4: {
            isPlanPurchased: false,
            isOnboarded: false,
            hasBankPermission: false,
            isAuthRegistered: false,
            hasDailyAuth: false
        }
    });
    const [error, setError] = useState({
        show: false,
        message: '',
        type: 'error'
    });

    const handleCloseSnackbar = () => {
        setError({ ...error, show: false });
    };

    const showError = (message, type = 'error') => {
        setError({
            show: true,
            message,
            type
        });
    };
    const showSuccess = (message, type = 'success') => {
        setError({
            show: true,
            message,
            type
        });
    };

    const checkBankPermission = (aepsData, type) => {
        if (!aepsData?.bankActiveStatus) return false;
        
        return type === 'Aeps2' 
            ? (aepsData.bankActiveStatus.Bank2 || aepsData.bankActiveStatus.Bank3)
            : aepsData.bankActiveStatus.Bank4;
    };

    const fetchPermissions = async () => {
        setLoading(true);
        try {
            const response = await UserPermissionAeps();

            if (response?.data?.statusCode == '200') {
                const data = response.data.data;
                setMerchantCode(data.merchantCode);

                const newAepsData = {
                    Aeps2: {
                        isPlanPurchased: data.is_active && data.is_buy,
                        isOnboarded: data.Aeps2?.is_onboarding || false,
                        hasBankPermission: checkBankPermission(data.Aeps2, 'Aeps2'),
                        isAuthRegistered: data.Aeps2?.authRegistered || false,
                        hasDailyAuth: data.Aeps2?.dailyAuth || false
                    },
                    Aeps4: {
                        isPlanPurchased: data.is_active && data.is_buy,
                        isOnboarded: data.Aeps4?.is_onboarding || false,
                        hasBankPermission: checkBankPermission(data.Aeps4, 'Aeps4'),
                        isAuthRegistered: data.Aeps4?.authRegistered || false,
                        hasDailyAuth: data.Aeps4?.dailyAuth || false
                    }
                };

                setAepsData(newAepsData);

                if (!newAepsData.Aeps2.isOnboarded && newAepsData.Aeps4.isOnboarded) {
                    setAepsType('Aeps4');
                }
            } else if (response?.data?.statusCode === '00127') {
                setBankServerDown(true);
            } else if (response?.data?.statusCode === '007') {
                setbankverify(true);
            } else {
                showError(response?.data?.message || response?.message );
            }
        } catch (error) {
            showError(error.message || 'Failed to fetch permissions');
        } finally {
            setLoading(false);
        }
    };

    const handleAepsTypeChange = (event) => {
        setAepsType(event.target.value);
    };

    const handleOnboarding = async () => {
        setLoading(true);
        try {
            const data = {
                user_id: localStorage.getItem('userIdToken'),
                bank: apiAepsType
            };
            
            const response = await aepsOnboard(data);
            
            if (response?.data?.statusCode == 200) {
                window.open(response.data.data.url, '_blank');
                showError(response?.data?.message || response?.message || 'Onboarding successful', 'success');
                await fetchPermissions();
            } else {
                throw new Error(response?.data?.message || 'Onboarding failed');
            }
        } catch (error) {
            showError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPermissions();
    }, []);

    const renderCurrentStep = () => {
        const currentAepsData = aepsData[aepsType];

        if (!currentAepsData.isPlanPurchased) {
            return <PermissionAepsOnboarding />;
        }

        if (!currentAepsData.isOnboarded) {
            return <SuggestOnboarding onBoardingApiCall={handleOnboarding} apiAepsType={apiAepsType} permissionAeps={fetchPermissions} setbankverify={setbankverify}   isInstantpayOnBoarded={aepsType === 'Aeps4'}  merchnadecode={merchnadecode}/>;
        }
        
        if (!currentAepsData.hasBankPermission) {
            return (
                <BankPermission 
                    profiledata={profiledata} 
                    onDailyAuthComplete={() => fetchPermissions()} 
                    apiAepsType={apiAepsType} 
                    merchnadecode={merchnadecode}
                />
            );
        }
        

        if (!currentAepsData.isAuthRegistered) {
            return <AuthResister onDailyAuthComplete={() => fetchPermissions()}   apiAepsType={apiAepsType} />;
        }

        if (!currentAepsData.hasDailyAuth) {
            return <DailyAuth onDailyAuthComplete={() => fetchPermissions()}   apiAepsType={apiAepsType}/>;
        }

        return <CasDepositTab isInstantpayOnBoarded={aepsType === 'Aeps4'} apiAepsType={apiAepsType}   />;
    };

    return (
        <div className="cash-deposit-page">
            {loading && <Loader/>}
            
            <div className="aeps-type-selector" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',marginTop:'2%' }}>
    <FormControl component="fieldset" style={{ width: '100%', maxWidth: '400px' }}>
        <FormLabel>Select AEPS Type</FormLabel>
        <RadioGroup value={aepsType} onChange={handleAepsTypeChange} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormControlLabel
                value="Aeps2"
                control={<Radio />}
                label={
                    <>
                        AEPS 2 
                        {aepsData.Aeps2.isOnboarded && (
                            <span style={{ color: 'green', marginLeft: '8px' }}>
                                ✔ (Onboarded)
                            </span>
                        )}
                    </>
                }
            />
            <FormControlLabel
                value="Aeps4"
                control={<Radio />}
                label={
                    <>
                        AEPS 4
                        {aepsData.Aeps4.isOnboarded && (
                            <span style={{ color: 'green', marginLeft: '8px' }}>
                                ✔ (Onboarded)
                            </span>
                        )}
                    </>
                }
            />
        </RadioGroup>
    </FormControl>
</div>


            {renderCurrentStep()}

            <Bank4aepsverify
                show={bankverify}
                onHide={() => setbankverify(false)} 
                permissionAeps={fetchPermissions}
            />

            {bankServerDown && (
                <div style={{ 
                    textAlign: 'center', 
                    padding: '20px', 
                    backgroundColor: '#ffcccc', 
                    borderRadius: '5px',
                    margin: '20px auto',
                    maxWidth: '400px'
                }}>
                    <h2 style={{ color: '#cc0000' }}>Bank Server is Down</h2>
                    <p>We apologize for the inconvenience. Please try again later.</p>
                </div>
            )}

            <Snackbar
                open={error.show}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={error.message}
                action={
                    <IconButton size="small" onClick={handleCloseSnackbar}>
                        <CloseIcon />
                    </IconButton>
                }
            />
        </div>
    );
}

export default CashDepositPage;